'use client';

import React from 'react';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import Link from 'next/link';

import deviceScreenSize, { ScreenSize } from '../../store/deviceScreenSize';
import { theme } from '../../store/theme';
import AnimatedTitle from '../AnimatedTitle';

import Button, { ButtonVariants } from '../Button';
import Img from '../Img';
import Logo from '../Logo';

import styles from './Error.module.scss';

const UnderMaintenance = () => {
  const deviceSize = useAtomValue(deviceScreenSize);
  const appTheme = useAtomValue(theme);

  return (
    <>
      <div
        aria-hidden='true'
        className={styles.background}
      >
        <span className={classNames(styles.ellipse, styles.ellipse__bottom_left)} />
        <span className={classNames(styles.ellipse, styles.ellipse__top_right)} />
        <Img
          src='/media/structure.svg'
          width={deviceSize < ScreenSize.DESKTOP_SMALL ? 648 : 1078}
          height={deviceSize < ScreenSize.DESKTOP_SMALL ? 320 : 533}
          className={styles.structure_top}
          alt='structure image'
        />
        <Img
          src='/media/structure.svg'
          width={deviceSize < ScreenSize.DESKTOP_SMALL ? 648 : 1078}
          height={deviceSize < ScreenSize.DESKTOP_SMALL ? 320 : 533}
          className={styles.structure_bottom}
          alt='structure image'
        />
      </div>
      <section className={styles.wrapper}>
        <div className={styles.animationGroup}>
          <Img
            width={deviceSize === ScreenSize.MOBILE ? 24 : 27}
            height={deviceSize === ScreenSize.MOBILE ? 24 : 27}
            src={`/media/polygons/polygon-gradient-${appTheme}.svg`}
            className={styles.animationGroup__smallGradient}
            alt='polygon'
          />
          <Img
            width={deviceSize === ScreenSize.MOBILE ? 71 : 80}
            height={deviceSize === ScreenSize.MOBILE ? 71 : 80}
            src={`/media/polygons/polygon-gradient-${appTheme}.svg`}
            className={styles.animationGroup__largeGradient}
            alt='polygon'
          />
          <Img
            width={deviceSize === ScreenSize.MOBILE ? 119 : 134}
            height={deviceSize === ScreenSize.MOBILE ? 119 : 134}
            src={`/media/polygons/polygon-border-${appTheme}.svg`}
            className={styles.animationGroup__noise}
            alt='polygons'
          />
        </div>

        <div className={classNames(styles.content, styles.content_marginTop)}>
          {/* Using a normal anchor tag until Link works on the error page */}
          <Link
            href='/'
            aria-label='Get back to main page'
            className={styles.logo}
          >
            <Logo
              width={119}
              height={32}
            />
          </Link>
          <h2 className={styles.title}>
            <AnimatedTitle title={'We\'re **Tinkering** Behind the Scenes. Stay Tuned!'} />
          </h2>
          <p className={styles.text}>
            Our tech crew is on it, working to resolve the issue and enhance your experience. We apologize for any inconvenience and thank
            you for your patience.
          </p>
          <Button
            link='/'
            className={styles.btn_short}
            variant={ButtonVariants.OUTLINED}
            icon={(
              <svg className={styles.btn__icon}>
                <use
                  xlinkHref='/media/icon-nest.svg#iconNest'
                  href='/media/icon-nest.svg#iconNest'
                />
              </svg>
            )}
            iconSize={{
              width: 24,
              height: 24,
            }}
          >
            Back to home
          </Button>
        </div>
      </section>
    </>
  );
};

export default UnderMaintenance;
