'use client';

import { useEffect } from 'react';
import UnderMaintenance from '../components/Errors/UnderMaintenance';

export default function Error({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);
  return <UnderMaintenance />;
}
