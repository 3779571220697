import classnames from 'classnames';
import styles from './Logo.module.scss';

interface LogoProps {
  width: number,
  height: number,
  className?: string,
}

const Logo = ({ width, height, className }: LogoProps) => (
  <div
    className={classnames(styles.logo, className)}
    style={{ width, height }}
  >
    <svg>
      <use
        width={width}
        height={height}
        xlinkHref='/media/logo.svg#logo'
        href='/media/logo.svg#logo'
      />
    </svg>
  </div>
);

export default Logo;
